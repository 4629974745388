import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><em parentName="p">{`Test Week`}</em></p>
    <p>{`Back Squat`}</p>
    <p>{`Front Squat`}</p>
    <p>{`Overhead Squat`}</p>
    <p>{`all to 1RM’s`}</p>
    <p>{`then, 5:00 of Sled Push (45/25) for distance (in 50ft increments on
turf).`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`Time is running out to get your team registered for the Granite
Games Fall Throwdown here at The Ville September 14th!  Register before
September 8th or you’ll miss out.`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`Learn more about the Throwdown here:
 `}<a parentName="em" {...{
            "href": "https://thegranitegames.com/about-throwdowns/"
          }}>{`https://thegranitegames.com/about-throwdowns/   `}</a></em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      